import './src/scss/theme.scss'
import('../../src/js/app')
require('owl.carousel')

$(document).ready(function () {
  $('.suggestion-slider .owl-carousel').owlCarousel({
    margin: 30,
    autoplay: true,
    autoplayHoverPause: false,
    slideTransition: 'linear',
    autoplaySpeed: 6000,
    smartSpeed: 6000,
    responsive: {
      0: {
        stagePadding: 70,
        loop: true,
        nav: false,
        items: 1,
        margin: 10
      },
      600: {
        stagePadding: 50,
        loop: true,
        nav: false,
        items: 2
      },
      1000: {
        loop: true,
        nav: false,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 3,
        stagePadding: 150
      },
      1350: {
        loop: true,
        nav: false,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 4,
        stagePadding: 150
      }
    }
  })

  $('.owl-carousel.owl-testimonials').owlCarousel({
    margin: 30,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        loop: true,
        nav: false,
        items: 1
      }
    }
  })
})
